import React from "react";
import loadable from "@loadable/component";

const Layout = loadable(() => import("@common/Layout/Layout"));
const PrivacyPolicy = loadable(() =>
    import("@components/PrivacyPolicy/PrivacyPolicy")
);
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const PrivacyPolicyPage = ({ path }) => (
    <>
        <SEO
            title="Privacy Policy: Collected and Recorded Information"
            titleTemplate="Impressit"
            description="The privacy of our visitors is our priority. For more details on how we use data, please check our Privacy Policy section."
            path={path}
        />
        <Layout path={path}>
            <PrivacyPolicy />
        </Layout>
    </>
);

export default PrivacyPolicyPage;
